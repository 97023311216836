var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"trash-list-path":"settings-users-notifications-trash-list","is-searchable":false,"can-create":_vm.ACCESS_ABILITY_USER_SETTINGS,"can-show-trash-list":_vm.ACCESS_ABILITY_USER_SETTINGS,"is-pagination-available":false,"create-page-url":{ name: 'settings-users-notifications-create' },"table-config-options":{
      data: {
        is_active: 1
      }
    }},scopedSlots:_vm._u([{key:"cell(value)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.value + '%')+" ")]}},{key:"cell(is_active)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_USER_SETTINGS.action,"a":_vm.ACCESS_ABILITY_USER_SETTINGS.subject}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"title":_vm.$t('Set Inactive'),"icon":"LTrashIconKits","size":"32"},on:{"click":function($event){return _vm.setInActive(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"ml-1 cursor-pointer",attrs:{"title":_vm.$t('View'),"icon":"LStockEyeIcon","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'settings-users-notifications-update', params: { id: data.item.id } }, $event)}}}),(data.item.triggers.length > 0)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"ml-1 cursor-pointer",attrs:{"title":_vm.$t('Dublicate'),"icon":"LCopyIconKits","size":"32"},on:{"click":function($event){return _vm.duplicateNot(data.item)}}}):_vm._e()],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }