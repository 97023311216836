<template>
  <div class="pb-2">
    <!--    <h4 class="text-center font-weight-bolder font-medium-5">-->
    <!--      {{ $t('Notifications List') }}-->
    <!--    </h4>-->
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      trash-list-path="settings-users-notifications-trash-list"
      :is-searchable="false"
      :can-create="ACCESS_ABILITY_USER_SETTINGS"
      :can-show-trash-list="ACCESS_ABILITY_USER_SETTINGS"
      :is-pagination-available="false"
      :create-page-url="{ name: 'settings-users-notifications-create' }"
      :table-config-options="{
        data: {
          is_active: 1
        }
      }"
    >
      <template #cell(value)="{ data }">
        {{ data.item.value + '%' }}
      </template>

      <template #cell(is_active)="{ data }">
        {{ data.item.is_active ? 'Active' : 'Inactive' }}
      </template>

      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_USER_SETTINGS.action"
            :a="ACCESS_ABILITY_USER_SETTINGS.subject"
          >
            <div class="d-flex justify-content-start">
              <feather-icon
                v-b-tooltip.noninteractive.hover.bottom
                :title="$t('Set Inactive')"
                icon="LTrashIconKits"
                size="32"
                class="cursor-pointer"
                @click="setInActive(data.item)"
              />
              <feather-icon
                v-b-tooltip.noninteractive.hover.bottom
                :title="$t('View')"
                icon="LStockEyeIcon"
                size="32"
                class="ml-1 cursor-pointer"
                @click="gotoNewPage({ name: 'settings-users-notifications-update', params: { id: data.item.id } }, $event)"
              />
              <feather-icon
                v-if="data.item.triggers.length > 0"
                v-b-tooltip.noninteractive.hover.bottom
                :title="$t('Dublicate')"
                icon="LCopyIconKits"
                class="ml-1 cursor-pointer"
                size="32"
                @click="duplicateNot(data.item)"
              />
            </div>
          </Can>
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip } from 'bootstrap-vue'
import notificationConfig from '../notificationsConfig'

export default {
  name: 'TaxesList',
  components: {
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    duplicateNot(data) {
      this.isLoading = true
      this.sendNotification(this, data, `${this.MODULE_NAME}/duplicate`)
        .then(() => {
          this.clear()
        })
        .catch(err => {
          this.error = err.response?.data
        }).finally(() => {
          this.isLoading = false
          this.refetchData()
        })
    },

    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      this.confirmNotification(this, { id: data.id, is_active: false }, `${this.MODULE_NAME}/update`, {
        text: 'It is possible to revert this',
        confirmButtonText: 'Yes, deactivate it',
      }).then(() => {
        this.refetchData()
      }).catch(err => {
        this.error = err.response?.data
      })
    },
  },
  setup() {
    const MODULE_NAME = 'notification'
    const { tableColumns, fields, ACCESS_ABILITY_USER_SETTINGS } = notificationConfig()
    return {
      tableColumns,
      MODULE_NAME,
      fields,
      ACCESS_ABILITY_USER_SETTINGS,
    }
  },
}
</script>
